<template>
  <div class="settings">
    <ValidationObserver ref="form">
      <form
        id="kt_form"
        novalidate
        class="form"
        @submit.prevent="onSubmit"
      >
        <b-card>
          <b-tabs>
            <b-tab
              v-for="(value, propertyName,index) in form"
              :key="index"
              no-body
              :title="filterText(propertyName, true)"
            >
              <div class="row justify-content-center">
                <b-col
                  cols="12"
                >
                  <div
                    v-for="(item,name,i) in value"
                    :key="i"
                  >
                    <ValidationProvider
                      v-if="item.data_type==='string' || item.data_type==='float' || item.data_type==='image' || item.data_type==='text' || item.data_type==='date'"
                      v-slot="{ errors }"
                      :vid="item.key"
                      :name="item.key"
                      :rules="item.is_required ?'required': ''"
                    >
                      <b-form-group
                        :label="filterText(name, false, propertyName)"
                      >
                        <b-form-input
                          v-if="(item.data_type==='string' || item.data_type==='float')"
                          v-model="item.value"
                          class="form-control form-control-solid"
                          :type="item.data_type==='float'?'number':'text'"
                          :placeholder="filterText(item.key, false, propertyName)"
                        />
                        <ImageUpload
                          v-if="item.data_type==='image'"
                          v-model="item.value"
                          :label="''"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </ValidationProvider>

                    <div v-if="item.data_type==='json'">
                      <LocaleTabs
                        name="title"
                      >
                        <template
                          v-for="(i,k,idx) in languages"
                          :slot="`lang${idx}`"
                          slot-scope="slotScope"
                        >
                          <ValidationProvider
                            :key="idx"
                            v-slot="{ errors }"
                            :class="slotScope.selectedTab!==idx?'hidden':''"
                            :vid="item.key[k]"
                            :name="`${i}`"
                            :rules="item.is_required ? 'required':''"
                          >
                            <b-form-group :label="`${filterText(item.key, false, propertyName)}`">
                              <b-form-input
                                v-model="item.value[k]"
                                :placeholder="`${i}`"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </ValidationProvider>
                        </template>
                      </LocaleTabs>
                    </div>

                    <div v-if="item.data_type === 'array'">
                      <p class="text-capitalize">
                        {{ filterText(item.key, false, propertyName) }}
                      </p>
                      <b-row
                        v-for="(the_item,the_index) in item.value"
                        :key="the_index"
                      >
                        <div class="col-12">
                          <LocaleTabs
                            :name="the_item.title"
                          >
                            <template
                              v-for="(lang_item,lang_k,lang_idx) in the_item.title"
                              :slot="`lang${lang_idx}`"
                              slot-scope="slotScope"
                            >
                              <ValidationProvider
                                :key="lang_idx"
                                v-slot="{ errors }"
                                :class="slotScope.selectedTab!==lang_idx?'hidden':''"
                                :vid="`${lang_k}`"
                                :name="`Title`"
                                :rules="the_item.is_required ? 'required':''"
                              >
                                <b-form-group :label="`Title`">
                                  <b-form-input
                                    v-model="the_item.title[lang_k]"
                                    :placeholder="`${lang_item}`"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                              </ValidationProvider>
                            </template>
                          </LocaleTabs>
                        </div>
                        <div
                          class="col-12"
                        >
                          <LocaleTabs
                            :name="the_item.description"
                          >
                            <template
                              v-for="(lang_item,lang_k,lang_idx) in the_item.description"
                              :slot="`lang${lang_idx}`"
                              slot-scope="slotScope"
                            >
                              <ValidationProvider
                                :key="lang_idx"
                                v-slot="{ errors }"
                                :class="slotScope.selectedTab!==lang_idx?'hidden':''"
                                :vid="`${lang_k}`"
                                :name="`Description`"
                                :rules="the_item.is_required ? 'required':''"
                              >
                                <b-form-group :label="`Description`">
                                  <b-form-textarea
                                    v-model="the_item.description[lang_k]"
                                    :placeholder="`${lang_item}`"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                              </ValidationProvider>
                            </template>
                          </LocaleTabs>
                        </div>
                        <div class="col-12">
                          <ImageUpload
                            v-model="the_item.icon"
                            :label="''"
                          />
                          <hr class="my-4">
                        </div>
                      </b-row>
                    </div>

                    <b-form-group
                      v-if="item.data_type==='boolean'"
                      :label="filterText(item.key, false, propertyName)"
                    >
                      <span class="switch switch-icon">
                        <label>
                          <input
                            v-model="item.value"
                            type="checkbox"
                            true-value="true"
                            false-value="false"
                          >
                          <span />
                        </label>
                      </span>
                    </b-form-group>
                  </div>
                </b-col>
              </div>
            </b-tab>
          </b-tabs>
          <b-row>
            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
            </b-col>
          </b-row>
        </b-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      form: null,
    }
  },
  computed: {
    languages() {
      return {
        en: 'English',
        ar: 'Arabic',
      }
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.axios.get('/settings')
        .then(res => {
          this.form = res.data.data
        })
    },
    loadFormData() {
      let $fields = _.cloneDeep(this.form)
      $fields = {
        ...$fields.homePage,
        ...$fields.about,
        ...$fields.general,
        ...$fields.socialMedia,
        ...$fields.contactUs,
        ...$fields.content,
        ...$fields.statistics,
        ...$fields.about,
      }
      return $fields
    },
    onSubmit() {
      const route = `/${this.resource}`
      const $form = this.loadFormData()
      this.$store.dispatch('app/isLoading', true)
      this.$refs.form.validate()
        .then(result => {
          if (result) {
            this.axios.put(route, $form)
              .then(response => {
                this.$store.dispatch('app/isLoading', false)
                this.$bvToast.toast(response.data.message, {
                  title: 'Success',
                  variant: 'success',
                  solid: true,
                  autoHideDelay: 3000,
                  appendToast: true,
                })
              })
              .catch(error => {
                this.$store.dispatch('app/isLoading', false)
                this.$bvToast.toast(error.response.data.message, {
                  title: 'Error',
                  variant: 'danger',
                  solid: true,
                  autoHideDelay: 5000,
                  appendToast: true,
                })
                this.$refs.form.setErrors(error.response.data.errors)
              })
          } else {
            this.formErrors = this.$refs.form.errors
          }
        })
    },
    filterText(text, isMain = false, group = null) {
      const str = text
      if (isMain) {
        return this.toUpperWord(this.toKebabCase(str).replace('-', ' '))
      }
      if (group) {
        const groupName = this.toKebabCase(group).replace('-', '_')
        return this.toUpperWord(this.removeKeywords(str).replace(`${groupName}_`, '').replace(/_/g, ' '))
      }
      return str.replace(/_/g, ' ')
    },
    getValueInt(val) {
      return parseInt(val, 10)
    },
    toKebabCase(str) {
      return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
    },
    toUpperWord(str) {
      return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
    },
    removeKeywords(str) {
      const keywords = ['homepage_social_', 'contact_', 'homepage_']
      keywords.forEach(keyword => {
        str = str.replace(keyword, '')
      })
      return str
    },
  },
}
</script>

<style lang="scss">
.settings .nav .nav-link {
  text-transform: capitalize;
}
</style>
